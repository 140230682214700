<template>
  <!-- footer -->
  <section   :class="{ ' color footer-container w-full text-white flex flex-col relative z-10 paddingBottom': paddingBottom, 'color footer-container w-full text-white flex flex-col relative z-10': !paddingBottom}">
    <div class="socials m-auto -mt-4 flex">
      <a v-for="rs in socials"
         class="mr-4"
         :href="rs.url" target="_blank" :key="rs.url">
        <img :src="rs.image" :alt="rs.url" width="34" height="34"/>
      </a>
    </div>
    <div class="footer-layout w-page mx-auto page-padding flex flex-col">
      <div class="footer-router my-0 mx-auto flex-shrink-0 mt-4">
        <router-link :to="item.route"
                     class="inline-block mr-4 cursor-pointer text-lg transition hover:underline"
                     v-for="item in links"
                     :key="item.label">
          {{ item.label }}
        </router-link>
      </div>
      <div class="footer-information text-center mt-4 mb-4">
        <span class="font-bold">Lodylearn {{ year }} • </span>
        <button @click="openCgv" class="cursor-pointer transition hover:underline"> Conditions Générales de Ventes
        </button>
        •
        <button @click="openLegals" class="cursor-pointer transition hover:underline"> Mentions légales
        </button>
        •
        <button @click="openPrivacy" class="cursor-pointer transition hover:underline"> Politique de
          confidentialité
        </button>
        •
        <button @click="openHandicap" class="cursor-pointer transition hover:underline"> Handicap
        </button>
      </div>
    </div>
  </section>
  <!-- end of footer -->
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

// import facebook from '../../assets/images/rs/Icone-RS_FB_fond.svg';
// import instagram from '../../assets/images/rs/Icone-RS_Insta_fond.svg';
// import linkedin from '../../assets/images/rs/Icone-RS_Linkedin_fond.svg';
// import youtube from '../../assets/images/rs/Icone-RS_Youtube_fond.svg';
const facebook = null;
const instagram = null;
const linkedin = null;
const youtube = null;

@Component({
  name: 'default-footer',
  props: {
    color: {
      type: String,
      default: 'bg-primary-800',
    },
    paddingBottom: {
      type: Boolean,
      default: 0,
    },
  },
  data() {
    return {
      socials: [
        // {
        //   image: facebook,
        //   url: 'https://facebook.com',
        // },
        // {
        //   image: instagram,
        //   url: 'https://instagram.com',
        // },
        // {
        //   image: linkedin,
        //   url: 'https://linkedin.com',
        // },
        // {
        //   image: youtube,
        //   url: 'https://youtube.com',
        // },
      ],
      links: [
        {
          label: 'Les modules',
          route: { name: this.$routeNames.Search },
        },
        {
          label: 'La plateforme',
          route: { name: this.$routeNames.Platform },
        },
        {
          label: 'Offre école/entreprise',
          route: { name: this.$routeNames.Offers },
        },
        {
          label: 'Live & MasterClass',
          route: { name: this.$routeNames.LiveMasterClass },
        },
        {
          label: 'Contacts',
          route: { name: this.$routeNames.Contact },
        },
        {
          label: 'Aide & FAQ',
          route: { name: this.$routeNames.Home },
        },
      ],
    };
  },
  computed: {
    year() {
      return (new Date()).getUTCFullYear();
    },
  },
  methods: {
    openCgv() {
      this.$store.commit('globalModal/cgv/SET_OPEN', true);
    },
    openHandicap() {
      this.$store.commit('globalModal/handicap/SET_OPEN', true);
    },
    openLegals() {
      this.$store.commit('globalModal/legals/SET_OPEN', true);
    },
    openPrivacy() {
      this.$store.commit('globalModal/privacy/SET_OPEN', true);
    },
  },
})
export default class DefaultFooter extends Vue {
}
</script>

<style scoped>

</style>
